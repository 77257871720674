import React from "react";

const SporcleIcon = () => {
	return (
		<svg width="24" height="24" viewBox="0 0 24 24">
			<g fill="#ffffff">
				<g id="g4327" className="" fill="#ffffff">
					<path
						id="path4297"
						d="m12.079113006591797,17.144567489624023 l0,0.0022169719450175762 l-1.680048942565918,0.06819959729909897 c0.5595858693122864,2.0418643951416016 -1.7106597423553467,2.5513908863067627 -3.141432762145996,3.349273681640625 c1.558802843093872,0.6837916970252991 3.17301869392395,0.9254416823387146 4.821481227874756,0.8919875621795654 c0.004988186527043581,-0.00010530616418691352 0.010087222792208195,0.00010530616418691352 0.01513083279132843,0 c1.6484626531600952,0.03347627446055412 3.262678861618042,-0.20819583535194397 4.821481227874756,-0.8919875621795654 c-1.4307726621627808,-0.797882616519928 -3.7010183334350586,-1.3074148893356323 -3.141432762145996,-3.349273681640625 l-1.680048942565918,-0.06819959729909897 l0,-0.0022169719450175762 l-0.007593128364533186,0.0011084859725087881 l-0.007593128364533186,-0.0011084859725087881 zm-6.536170482635498,3.8093395233154297 c-0.5720784664154053,0.17071238160133362 -1.9524649381637573,0.5775101184844971 -1.901962161064148,1.3920974731445312 a8.798606872558594,2.4594533443450928 0 0 0 8.321221351623535,1.6681437492370605 a8.798606872558594,2.4594533443450928 0 0 0 8.313644409179688,-1.6638097763061523 c0.05567370727658272,-0.8133071660995483 -1.313345193862915,-1.2204872369766235 -1.8922187089920044,-1.3931838274002075 a6.450881004333496,1.803199052810669 0 0 1 -6.421425819396973,1.6389130353927612 a6.450881004333496,1.803199052810669 0 0 1 -6.4192585945129395,-1.6421608924865723 z"
						fill="#ffffff"
					/>
					<g id="g4319" fill="#ffffff">
						<circle
							id="path4209"
							cx="72.8236665725708"
							cy="85.1536462457043"
							r="15.942001342773438"
							transform="matrix(0.554243, 0, 0, 0.554243, -28.362, -38.3737)"
						/>
						<circle
							r="15.942001342773438"
							cy="85.1536462457043"
							cx="72.8236665725708"
							id="circle4211"
							transform="matrix(0.554243, 0, 0, 0.554243, -28.362, -38.3737)"
						/>
						<circle
							id="circle4291"
							cx="72.8236665725708"
							cy="85.1536462457043"
							r="15.942001342773438"
							transform="matrix(0.554243, 0, 0, 0.554243, -28.362, -38.3737)"
						/>
						<circle
							r="15.942001342773438"
							cy="85.1536462457043"
							cx="72.8236665725708"
							id="circle4295"
							transform="matrix(0.554243, 0, 0, 0.554243, -28.362, -38.3737)"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export default SporcleIcon;
