export default [
	{
		key: "H",
		name: "Head"
	},
	{
		key: "I",
		name: "Interim"
	},
	{
		key: "A",
		name: "Assistant"
	}
];
